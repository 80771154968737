import {Dispatch, FormEvent, SetStateAction} from "react";
import {v4} from "uuid";
import {t} from "i18next";
import {subscribeUserToPush} from "../serviceWorkerRegistration";
import {notificationDispatch} from "./types";
import {AccountInfo} from "@azure/msal-common";
import {IPublicClientApplication} from "@azure/msal-browser";

export const handleSorting = (arrayToSort: any[], sortField: string, sortOrder: string, setSortedArray: Dispatch<SetStateAction<any[]>>, setSortField: Dispatch<SetStateAction<string>>) =>
{
    setSortField(sortField);

    const sorted = [...arrayToSort].sort((a, b) =>
    {
        let properA = a[sortField];
        let properB = b[sortField];

        if(sortField === "verified_status")
        {
            properA = a.verified_status.status;
            properB = b.verified_status.status;
        }

        if(sortField === "branch")
        {
            properA = a.branch.branchAddress;
            properB = b.branch.branchAddress;
        }

        if (properA === null) return 1;
        if (properB === null) return -1;
        if (properA === null && properB === null) return 0;
        return (
            properA.toString().localeCompare(properB.toString(), "en",
            {
                numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
    });

    setSortedArray(sorted);
};

export const onImageDownload = (qrCodeId: string, branchName: string) =>
{
    const svg = document.getElementById("QRCode" + qrCodeId);
    const svgData = new XMLSerializer().serializeToString(svg!);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () =>
    {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode_" + branchName;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export const formValidation = (event: FormEvent<HTMLFormElement>, formsToValidate: {inputName: string, input: any, skip: boolean}[], notificationDispatch: Dispatch<any>, handleSubmit: () => void) =>
{
    event.preventDefault();

    for(let i = 0; i < formsToValidate.length; i++)
    {
        if(formsToValidate[i].input !== undefined && !formsToValidate[i].input.trim().length && !formsToValidate[i].skip)
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: `${formsToValidate[i].inputName}${t("app.emptyError")}`
                    }
            })

            return;
        }
    }

    handleSubmit();

}

export const handleNotifications = async (token: {accounts: AccountInfo[], instance: IPublicClientApplication}, notificationDispatch: Dispatch<notificationDispatch>, setHideNotificationPrompt: Dispatch<SetStateAction<boolean>>) =>
{
    await Notification.requestPermission(async result =>
    {
        if (result !== "granted") throw new Error("We weren't granted permission.");

        await subscribeUserToPush(notificationDispatch, token);
        setHideNotificationPrompt(true);
    })
}
