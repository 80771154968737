import React, {Dispatch, FC, FormEvent, SetStateAction, useContext, useState} from 'react';
import styles from "./css/Form.module.css";
import {branch} from "../helpers/types";
import Context from "../helpers/Context";
import {formValidation} from "../helpers/helperFunctions";
import {v4} from "uuid";
import {useTranslation} from "react-i18next";
import {addNewBranch, modifyBranch} from "../helpers/apiEndPoints";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    isEdit: boolean;
    setEditClicked: Dispatch<SetStateAction<boolean>>;
    branches: branch[];
    setBranches: Dispatch<SetStateAction<branch[]>>;
    branchIndex: number;
}

const BranchForm: FC<IProps> = ({isEdit, setEditClicked, branches, setBranches, branchIndex}) =>
{
    const {notificationDispatch} = useContext(Context);
    const {accounts, instance} = useMsal();

    const [name, setName] = useState<string | undefined>(isEdit ? branches[branchIndex].name : "");
    const [address, setAddress] = useState<string | undefined>(isEdit ? branches[branchIndex].address : "");
    const [city, setCity] = useState<string | undefined>(isEdit ? branches[branchIndex].city : "");
    const [postalCode, setPostalCode] = useState<string | undefined>(isEdit ? branches[branchIndex].postal_code : "");

    const {t} = useTranslation();

    const handleSubmit = async () =>
    {
        const branch = {id: branches[branchIndex]?._id ?? "", name: name?.trim().replace(/\s\s+/g, ' ') ?? "",
        address: address?.trim().replace(/\s\s+/g, ' ') ?? "", city: city?.trim().replace(/\s\s+/g, ' ') ?? "",
        postalCode: postalCode?.trim().replace(/\s\s+/g, ' ') ?? ""}

        if(isEdit)
        {
            const modifiedBranch = await modifyBranch(branch, notificationDispatch, {accounts: accounts, instance: instance});
            if(modifiedBranch)
            {
                setEditClicked(false);

                setBranches(prevState =>
                {
                    prevState[branchIndex].name = modifiedBranch.name;
                    prevState[branchIndex].address = modifiedBranch.address;
                    prevState[branchIndex].city = modifiedBranch.city;
                    prevState[branchIndex].postal_code = modifiedBranch.postal_code;
                    return [...prevState];
                });
            }

        } else
        {
            const newBranch = await addNewBranch(branch, notificationDispatch, {accounts: accounts, instance: instance});

            if(newBranch)
            {
                setBranches(prevState => [...prevState, newBranch]);
                setEditClicked(false);
            }
        }
    }

    return (
        <div className={styles.edit_form_container} onClick={() => setEditClicked(current => !current)} onTouchEnd={() => setEditClicked(current => !current)}>
            <div className={styles.edit_form} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <form className={`${styles.form} + ${styles.form_edit}`}
                      onSubmit={e => formValidation(e, [{inputName: "Name", input: name, skip: false}, {inputName: "Address", input: address, skip: false}, {inputName: "City", input: city, skip: false}, {inputName: "Postal Code", input: postalCode, skip: false}], notificationDispatch, handleSubmit)}>
                    <label>
                        {t("branchForm.name")}
                        <input type="text" value={name} required={true} onChange={e => setName(e.target.value)} />
                    </label>
                    <label>
                        {t("branchForm.address")}
                        <input type="text" value={address} required={true} onChange={e => setAddress(e.target.value)} />
                    </label>
                    <label>
                        {t("branchForm.city")}
                        <input type="text" value={city} required={true} onChange={e => setCity(e.target.value)} />
                    </label>
                    <label>
                        {t("branchForm.postalCode")}
                        <input type="text" value={postalCode} required={true} onChange={e => setPostalCode(e.target.value)}/>
                    </label>
                    <input type="submit" value={isEdit ? t("branchForm.saveBtn") as string : t("branchForm.addBtn") as string} />
                </form>
            </div>
        </div>
    );
}

export default BranchForm;
