import React, {FC, useContext, useEffect, useState} from 'react';
import styles from './css/List.module.css';
import {visitor} from "../helpers/types";
import {getAllVisitorsOrBranchVisitors, unverifyVisitor} from "../helpers/apiEndPoints";
import moment from "moment";
import {Icons} from "../helpers/Icons";
import VisitorForm from "./VisitorForm";
import Button from "./Button";
import {handleSorting} from "../helpers/helperFunctions";
import VerifiedForm from "./VerifiedForm";
import Context from "../helpers/Context";
import PageSort from "./PageSort";
import PageSortContext from "../helpers/PageSortContext";
import {v4} from "uuid";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import VisitorDeleteScreen from "./VisitorDeleteScreen";
import HostAdminVisitorFormModal from "./HostAdminVisitorFormModal";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    isControlPanel?: boolean;
    branchId?: string | undefined;
    branchAddress?: string | undefined;
    isAdmin?: boolean;
}

const VisitorsList:FC<IProps> = ({branchId, isControlPanel= false, isAdmin, branchAddress}) =>
{
    const {accounts, instance} = useMsal();
    const {notificationDispatch} = useContext(Context);
    const {itemsPerPage, setNumOfPages, firstSlice, secondSlice} = useContext(PageSortContext);

    const [visitors, setVisitors] = useState<visitor[]>([]);
    const [visitorsForDeletion, setVisitorsForDeletion] = useState<visitor[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [verifyFilter, setVerifyFilter] = useState<string>("unverified");
    const [filteredVisitors, setFilteredVisitors] = useState<visitor[]>([]);

    const [verifiedClicked, setVerifiedClicked] = useState<boolean>(false);
    const [verifViewMoreIDs, setVerifViewMoreIDs] = useState<string[]>([]);

    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    const [editClicked, setEditClicked] = useState<boolean>(false);
    const [visitorIndex, setVisitorIndex] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<string>("asc");
    const [sortField, setSortField] = useState<string>("visitor_date");

    const [isDeleteOne, setIsDeleteOne] = useState<boolean>(false);
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

    const location = useLocation();
    const {t} = useTranslation();

    const [isAddVisitorClicked, setIsAddVisitorClicked] = useState<boolean>(false);
    const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState<boolean>(false);

    useEffect(() =>
    {
        if(isAddVisitorClicked)
        {
            document.getElementsByTagName("body")[0].style.overflow = "hidden"
        } else
        {
            document.getElementsByTagName("body")[0].style.overflow = "auto"
        }

    }, [isAddVisitorClicked])

    useEffect(() =>
    {
        if(visitors.length)
        {
            switch (verifyFilter)
            {
                case "verified":
                    setFilteredVisitors(visitors.filter(visitor => visitor.verified_status.status))
                    break;
                case "unverified":
                    setFilteredVisitors(visitors.filter(visitor => !visitor.verified_status.status))
                    break
                default:
                    return setFilteredVisitors(visitors);
            }
        }

    }, [verifyFilter, visitors]);


    useEffect(() =>
    {
        (async () =>
        {
            const url = isControlPanel ? `${process.env.REACT_APP_API_URL}/visitor` : `${process.env.REACT_APP_API_URL}/visitor/branch/${branchId}`;

            const allVisitors = await getAllVisitorsOrBranchVisitors(url, notificationDispatch, {accounts: accounts, instance: instance});
            if(allVisitors)
            {
                setVisitors(allVisitors);

                if(allVisitors.length > itemsPerPage)
                {
                    let calculateNumOfPages = allVisitors.length / itemsPerPage;

                    if(calculateNumOfPages % 1 !== 0 || calculateNumOfPages === 0) calculateNumOfPages = Math.trunc(calculateNumOfPages + 1);

                    setNumOfPages(calculateNumOfPages);
                }
            }
        })();
    }, [branchId]);

    useEffect(() =>
    {
        if(filteredVisitors.length > 0) setIsAllSelected(visitorsForDeletion.length === filteredVisitors.length);
    }, [visitorsForDeletion, searchQuery]);

    const handleVisitorsDeletionArray = (isChecked: boolean, visitor: visitor, isOneClicked: boolean = false): void =>
    {
        visitorsForDeletion.length !== 1 ? setIsDeleteOne(isOneClicked) : setIsDeleteOne(false);

        if(isChecked)
        {
            if(!visitorsForDeletion.includes(visitor)) setVisitorsForDeletion((prevState) => [...prevState, visitor]);
        } else
        {
            if(visitorsForDeletion.length > 0 && visitorsForDeletion.length === filteredVisitors.length && !isOneClicked) return setVisitorsForDeletion([]);
            setVisitorsForDeletion(visitorsForDeletion.filter(vis => vis._id !== visitor._id));
        }
    }

    const handleViewMore = (visitorID: string) =>
    {
        if(!verifViewMoreIDs.includes(visitorID))
        {
            setVerifViewMoreIDs(prev => [...prev, visitorID]);
        } else
        {
            setVerifViewMoreIDs(verifViewMoreIDs.filter(id => id !== visitorID));
        }
    }

    const handleVisitorVerification = async (visitor: visitor, isVerify: boolean) =>
    {
        if(isVerify)
        {
            setVerifiedClicked(current => !current)
        } else
        {
            const unverifiedVisitor = await unverifyVisitor(visitor._id, notificationDispatch, {accounts: accounts, instance: instance});

            if(unverifiedVisitor)
            {
                const index = filteredVisitors.indexOf(visitor);

                setVisitors(prevState =>
                {
                    prevState[index].verified_status.status = unverifiedVisitor.verified_status.status;
                    return [...prevState];
                });

                setFilteredVisitors(prevState =>
                {
                    prevState[index].verified_status.status = unverifiedVisitor.verified_status.status;
                    return [...prevState];
                });

                setVerifViewMoreIDs(verifViewMoreIDs.filter(id => id !== visitor._id));
            }
        }
    }

    return (
        <div className={styles.list_container}>
            <h3>{t("app.visitors")}</h3>
            <div className={styles.table_controls}>

                <div className={styles.search}>
                    <input type="text" value={searchQuery} placeholder={t("lists.search") as string} onChange={e => setSearchQuery(e.target.value)}/>
                    <Button onClick={() => setIsAddVisitorClicked(current => !current)}>{t("visitorsList.addVisitor")}</Button>
                </div>

                <div className={styles.filter_controls}>
                    <div className={styles.verify_filter}>
                        {t("lists.filter")}:
                        <select className={styles.items_controls} defaultValue={verifyFilter} onChange={e => setVerifyFilter(e.target.value)}>
                            <option value="all">{t("lists.all")}</option>
                            <option value="unverified">{t("visitorsList.filterUnverified")}</option>
                            <option value="verified">{t("visitorsList.filterVerified")}</option>
                        </select>
                    </div>

                    <div className={styles.page_sort_controls}>
                        <PageSort arrayToModify={searchQuery !== "" ? filteredVisitors.filter(visitor => visitor.name.toLowerCase().includes(searchQuery)) : filteredVisitors} searchQuery={searchQuery}/>
                    </div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        {isAdmin && <th className={styles.select_all}><input type="checkbox" checked={isAllSelected} onChange={e =>
                        {
                            setIsAllSelected(current => !current);
                            filteredVisitors.map(visitor => handleVisitorsDeletionArray(e.target.checked, visitor));
                        }
                        } /></th>}
                        <th>
                            <div onClick={() =>
                            {
                                handleSorting(visitors, "name", sortOrder, setVisitors, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                {t("visitorsList.name")}{sortField === "name" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th>
                            <div onClick={() =>
                            {
                                handleSorting(visitors, "organization", sortOrder, setVisitors, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                {t("visitorsList.organization")}{sortField === "organization" ? sortOrder === "asc" ? Icons.ascArrow : Icons.descArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th>
                            <div onClick={() =>
                            {
                                handleSorting(visitors, "host_name", sortOrder, setVisitors, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                {t("visitorsList.hostName")}{sortField === "host_name" ? sortOrder === "asc" ? Icons.ascArrow : Icons.descArrow : Icons.unsorted}
                            </div>
                        </th>
                        <th>
                            <div onClick={() =>
                            {
                                handleSorting(visitors, "visitor_date", sortOrder, setVisitors, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                {t("visitorsList.visitingDate")}{sortField === "visitor_date" ? sortOrder === "asc" ? Icons.ascArrow : Icons.descArrow : Icons.unsorted}
                            </div>
                        </th>
                        {location.pathname.slice(1, 14) === "control-panel" &&
                            <th>
                                <div onClick={() =>
                                {
                                    handleSorting(visitors, "branch", sortOrder, setVisitors, setSortField);
                                    setSortOrder(current => current === "asc" ? "desc" : "asc");
                                }}>
                                    {t("visitorsList.branchAddress")}{sortField === "branch" ? sortOrder === "asc" ? Icons.ascArrow : Icons.descArrow : Icons.unsorted}
                                </div>
                            </th>
                        }
                        <th className={styles.verified}>
                            <div onClick={() =>
                            {
                                handleSorting(visitors, "verified_status", sortOrder, setVisitors, setSortField);
                                setSortOrder(current => current === "asc" ? "desc" : "asc");
                            }}>
                                {t("visitorsList.verified")}{sortField === "verified_status" ? sortOrder === "asc" ? Icons.ascArrow : Icons.descArrow : Icons.unsorted}
                            </div>
                        </th>
                        {isAdmin && <th>{t("lists.action")}</th>}
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(filteredVisitors) && filteredVisitors.filter(visitor => visitor.name.toLowerCase().includes(searchQuery.toLowerCase())).slice(firstSlice, secondSlice).map(visitor =>
                        <tr key={visitor._id}>
                            {isAdmin &&
                                <td>
                                    <input type="checkbox" checked={isAllSelected ? isAllSelected : visitorsForDeletion.includes(visitor)} onChange={e =>
                                    {
                                        if(isAllSelected) setIsAllSelected(false);
                                        handleVisitorsDeletionArray(e.target.checked, visitor, true)
                                    }} /></td>
                            }
                            <td data-label={t("visitorsList.name")}>{visitor.name}</td>
                            <td data-label={t("visitorsList.organization")}>{visitor.organization}</td>
                            <td data-label={t("visitorsList.hostName")}>{visitor.host_name}</td>
                            <td data-label={t("visitorsList.visitingDate")}>{moment(visitor.visitor_date).format("DD.MM.YYYY, HH:mm")}</td>
                            {location.pathname.slice(1, 14) === "control-panel" && <td data-label={t("visitorsList.branchAddress")}>{visitor.branch ? visitor.branch.branchAddress : ""}</td>}
                            <td data-label={t("visitorsList.verified")}>

                                <div className={`${styles.verified_info} ${visitor.verified_status.status ? styles.unverified : null}`}>
                                    <Button btnSize="btn-sm" btnStyle={visitor.verified_status.status ? "secondary" : "primary"} fontSize="1em"
                                            onClick={() =>
                                            {
                                                setVisitorIndex(filteredVisitors.indexOf(visitor));
                                                handleVisitorVerification(visitor, !visitor.verified_status.status);
                                            }}>{t(`visitorsList.${visitor.verified_status.status ? "unverify" : "verify"}`)}</Button>

                                    {verifViewMoreIDs.includes(visitor._id) &&
                                        <>
                                        <span>{t("visitorsList.verifyCardNumber")}</span>{visitor.verified_status.visitorCardNumber}
                                        <span>{t("visitorsList.verifyMethod")}</span>{t(`verificationForm.${visitor.verified_status.verificationMethod}`)}
                                        </>
                                    }

                                    {visitor.verified_status.status &&
                                        <button className={styles.verified_view_more}
                                           onClick={() => handleViewMore(visitor._id)}>{t("visitorsList.verifyView")} {verifViewMoreIDs.includes(visitor._id) ? t("visitorsList.verifyLess") : t("visitorsList.verifyMore")}{verifViewMoreIDs.includes(visitor._id) ? Icons.arrowUp : Icons.arrowDown}</button>
                                    }
                                </div>
                            </td>

                            {isAdmin &&
                                <td data-label={t("lists.action")}>
                                    <div className={styles.admin_controls}>
                                        <Button btnSize="btn-sm" onClick={() =>
                                        {
                                            setEditClicked(current => !current);
                                            setVisitorIndex(filteredVisitors.indexOf(visitor));
                                        }}>{t("lists.edit")}{Icons.edit}</Button>

                                        <Button btnSize="btn-sm" btnStyle="delete-btn" onClick={() =>
                                        {
                                            setDeleteClicked(true);
                                            handleVisitorsDeletionArray(true, visitor, true)
                                        }} disabled={(visitorsForDeletion.length === 1 && !visitorsForDeletion.includes(visitor)) || visitorsForDeletion.length >= 2}>{t("lists.delete")}{Icons.delete}</Button>
                                    </div>
                                </td>
                            }

                        </tr>
                    )}
                </tbody>
            </table>
            {isAdmin &&
                <div className={styles.delete_btn}>
                    <Button btnSize="btn-sm" btnStyle="delete-btn"
                            onClick={() => setDeleteClicked(current => !current)}
                            disabled={visitorsForDeletion.length === 0}>{t("visitorsList.deleteBtn")}{Icons.delete}</Button>
                </div>
            }
            {editClicked &&
                <VisitorForm
                    isEdit={true}
                    setEditClicked={setEditClicked}
                    visitors={filteredVisitors}
                    setVisitors={setVisitors}
                    visitorIndex={visitorIndex}
                    setFilteredVisitors={setFilteredVisitors} />
            }

            {verifiedClicked &&
                <VerifiedForm
                    setVerifiedClicked={setVerifiedClicked}
                    visitorIndex={visitorIndex}
                    visitors={filteredVisitors}
                    setVisitors={setVisitors}
                    setFilteredVisitors={setFilteredVisitors} />
            }

            {deleteClicked &&
                <VisitorDeleteScreen
                    setDeleteClicked={setDeleteClicked}
                    visitorsForDeletion={visitorsForDeletion}
                    isDeleteOne={isDeleteOne}
                    setVisitors={setVisitors}
                    setVisitorsForDeletion={setVisitorsForDeletion} />
            }

            {isAddVisitorClicked &&
                <HostAdminVisitorFormModal branchAddress={branchAddress} branchID={branchId} isRegistrationSuccessful={isRegistrationSuccessful}
                                           setIsRegistrationSuccessful={setIsRegistrationSuccessful}
                                           setIsAddVisitorClicked={setIsAddVisitorClicked} setVisitors={setVisitors} />
            }

        </div>
    );
}

export default VisitorsList;
