import React, {FC, useContext, useEffect, useState} from 'react';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {SignInButton} from "./SignInButton";
import styles from "./css/NavBar.module.css";
import Button from "./Button";
import {Icons} from "../helpers/Icons";
import {isAdmin, isHost} from "../helpers/checkers";
import Context from "../helpers/Context";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import NetumLogo from "../assets/netum-logo";
import SignOutButton from "./SignOutButton";

const NavBar: FC = () =>
{
    const {accounts} = useMsal();

    const {groupsID} = useContext(Context);

    const isAuthenticated = useIsAuthenticated();
    const [showHamburger, setShowHamburger] = useState<boolean>(false);

    const {t} = useTranslation();

    useEffect(() =>
    {
        if(showHamburger)
        {
            document.body.style.overflow = "hidden";
        } else
        {
            document.body.style.overflow = "";
        }

    },[showHamburger]);

    return (
        <header>
            <div className={styles.navbar_container}>
                {isAuthenticated ? <a href="/"><NetumLogo /></a> : <NetumLogo />}

                <div className={styles.controls_container}>
                    <span onClick={() => setShowHamburger(current => !current)}>{Icons.hamburgerMenu}</span>

                    <div className={`${styles.nav_menu} ${showHamburger ? styles.open : ''}`} >

                        <div className={`${styles.navigation}`}>
                            {isAuthenticated && <Button href="/" btnSize="btn-sm" selected={window.location.href.split("/")[3] === ""} btnStyle="secondary">{t("app.branches")}</Button> }
                            {(isHost(groupsID) || isAdmin(groupsID)) && <Button href="/control-panel" btnSize="btn-sm" btnStyle="secondary" selected={window.location.href.split("/")[3] === "control-panel"}>{t("app.controlPanel")}</Button>}
                        </div>

                        <div className={styles.nav_user_controls}>
                            <div className={styles.user_lang_container}>
                                {accounts[0] !== undefined && <span className={styles.user_name}>{accounts[0].name}</span>}
                                <div className={styles.language_selections}>
                                    <button className={localStorage.getItem("i18nextLng") === "fi-FI" ? styles.selected_language : undefined}
                                            onClick={() => i18next.changeLanguage("fi-FI")}
                                    >FI</button>
                                    <hr color="#fff" />
                                    <button className={localStorage.getItem("i18nextLng") === "en-US" ? styles.selected_language : undefined}
                                            onClick={() => i18next.changeLanguage("en-US")}
                                    >EN</button>
                                </div>
                            </div>


                            {isAuthenticated ? <SignOutButton /> : <SignInButton /> }
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default NavBar;
