import React, {useContext, useEffect, useState} from 'react';
import {Bar} from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {statsAllBranches} from "../../helpers/types";
import Context from "../../helpers/Context";
import {getAllBranchesStats} from "../../helpers/apiEndPoints";
import {useMsal} from "@azure/msal-react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function VisitorsAllBranches()
{
    const {accounts, instance} = useMsal();

    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);

    const [branchesStats, setBranchesStats] = useState<statsAllBranches[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() =>
    {
        (async () =>
        {
            const result = await getAllBranchesStats(notificationDispatch,{accounts: accounts, instance: instance});
            if(result)
            {
                setBranchesStats(result);
                setLabels(result.map((stats: statsAllBranches) => moment(stats.month).format("MMMM yyyy")));
            }
        })();
    }, [])


    const options = {
        responsive: true,
        scales: {
            x: {stacked: true}
        },
        plugins: {
            legend: {
                display: true
            },
            title: {
                display: true,
                text: `${t("stats.utilizationFor")}${t("stats.allBranches")}`,
            },
        },
    };

    const data =
        {
            labels,
            datasets: [
                {
                    label: t("stats.visitorsNum"),
                    data: branchesStats.map(stats => stats.numOfVisitors),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    minBarLength: 7
                }
            ]
        };

    return <Bar options={options} data={data} updateMode={"active"} />;
}

export default VisitorsAllBranches;
