import React, {FC, useContext, useEffect, useState} from 'react';
import styles from "../css/Notification.module.css";
import {Icons} from "../../helpers/Icons";
import {notification} from "../../helpers/types";
import Context from "../../helpers/Context";

interface IProps
{
    notification: notification;
}

const Notification: FC<IProps> = ({notification}) =>
{
    const {notificationDispatch} = useContext(Context);

    const [hideMessage, setHideMessage] = useState<boolean>(false);

    useEffect(() =>
    {
        setInterval(() =>
        {
            handleCloseNotification();
        }, 3000)
    }, []);

    const handleCloseNotification = (): void =>
    {
        setHideMessage(true);

        setInterval(() =>
        {
            notificationDispatch({
                type: "REMOVE_NOTIFICATION",
                id: notification.id
            })
        }, 1000);
    }

    return (
        <div className={`${styles.message_container} ${hideMessage ? styles.hide : ""}`}>
            <div className={styles.message_content_container}>
                <span className={`${styles.message_type} ${styles[notification.type]}`} />

                <div className={styles.message_content}>
                    <div className={styles.message_type_icon}
                         style={{color: notification.type === "error" ? "var(--error)" : notification.type === "warning" ? "var(--warning)" : "var(--success)"}}>
                        <>{Icons[notification.type as keyof Object]}</>
                    </div>

                    <div className={styles.message}>{notification.message}</div>

                    <div className={styles.close} onClick={handleCloseNotification}>{Icons.close}</div>

                </div>
            </div>
            <div className={`${styles.message_timer} ${styles[notification.type]}`} />
        </div>
    );
}

export default Notification;
