import DatePicker from "react-datepicker";
import React, {Dispatch, FC, SetStateAction} from "react";
import moment from "moment/moment";
import styles from "./css/DatePickers.module.css";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";

export const filterPassedTime = (time: Date) =>
{
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

interface IDateProps
{
    visitingDate: Date;
    setVisitingDate: Dispatch<SetStateAction<Date>>;
}

export const FormDatePicker: FC<IDateProps> = ({visitingDate, setVisitingDate}) =>
{
    const {t} = useTranslation();

    return (
        <label>
            {t("verificationForm.date")}
            <DatePicker
                minDate={new Date(Date.now())}
                selected={visitingDate}
                showTimeSelect
                timeIntervals={5}
                filterTime={filterPassedTime}
                dateFormat="dd.MM.yyyy HH:mm"
                timeFormat="HH:mm"
                selectsStart
                startDate={visitingDate}
                className={styles.date_picker}
                timeCaption={t("app.time").toString()}
                onChange={date =>
                {
                    if(!moment(date!).isSame(date, "day"))
                    {
                        setVisitingDate(moment(date!).set({"hour": 8, "minute": 0}).toDate());
                        return
                    }

                    setVisitingDate(moment(date!).startOf("minute").toDate());
/*                    setEndDate(moment(endDate).add(moment(endDate).diff(date!, "minute"), "minute").toDate());*/
                }} />
        </label>
    )
}
