import React, {useContext, useEffect, useState} from 'react';
import {Bar} from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {branch, statsAllBranches} from "../../helpers/types";
import Context from "../../helpers/Context";
import {getBranches, getPerBranchStats} from "../../helpers/apiEndPoints";
import styles from './css/StatisticsPerBranch.module.css'
import {useMsal} from "@azure/msal-react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function VisitorsPerBranch()
{
    const {accounts, instance} = useMsal();
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);

    const [branchStats, setBranchStats] = useState<statsAllBranches[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const [allBranches, setAllBranches] = useState<branch[]>([]);
    const [selectedBranchID, setSelectedBranchID] = useState<string>("");

    useEffect(() =>
    {
        (async () =>
        {
            const branches = await getBranches(notificationDispatch, {accounts: accounts, instance: instance});
            if(branches) setAllBranches(branches);
        })();
    }, [])

    useEffect(() =>
    {
        if(selectedBranchID.length)
        {
            (async () =>
            {
                const result = await getPerBranchStats(selectedBranchID, notificationDispatch, {accounts, instance});
                if(result)
                {
                    setBranchStats(result);
                    setLabels(result.map((stats: statsAllBranches) => moment(stats.month).format("MMMM yyyy")));
                }
            })();
        }
    }, [selectedBranchID])


    const options = {
        responsive: true,
        scales: {
            x: {stacked: true}
        },
        plugins: {
            legend: {
                display: true
            },
            title: {
                display: true,
                text: `${t("stats.utilizationFor")}${selectedBranchID !== "" ? allBranches[allBranches.findIndex(branch => branch._id === selectedBranchID)].address : t("stats.selectedBranch")}`,
            },
        },
    };

    const data =
        {
            labels,
            datasets: [
                {
                    label: t("stats.visitorsNum"),
                    data: branchStats.map(stats => stats.numOfVisitors),
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    minBarLength: 7
                }
            ]
        };

    return(
        <div className={styles.stats_per_branch}>
            <select value={selectedBranchID} onChange={e => setSelectedBranchID(e.target.value)}>
                <option value="" disabled>{t("stats.selectBranch")}</option>
                {allBranches.map(branch => <option key={branch._id} value={branch._id}>{`${branch.city}, ${branch.address}`}</option>)}
            </select>
            <Bar options={options} data={data} updateMode={"active"} />
        </div>
    );
}

export default VisitorsPerBranch;
