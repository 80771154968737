import React, {Dispatch, FC, SetStateAction, useContext} from 'react';
import styles from "./css/VisitorDeleteScreen.module.css";
import {useTranslation} from "react-i18next";
import Button from "./Button";
import {visitorsDeletion} from "../helpers/apiEndPoints";
import Context from "../helpers/Context";
import {visitor} from "../helpers/types";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    setDeleteClicked: Dispatch<SetStateAction<boolean>>;
    visitorsForDeletion: visitor[];
    setVisitors: Dispatch<SetStateAction<visitor[]>>;
    setVisitorsForDeletion: Dispatch<SetStateAction<visitor[]>>;
    isDeleteOne: boolean;
}

const VisitorDeleteScreen: FC<IProps> = ({setDeleteClicked, visitorsForDeletion, setVisitors, setVisitorsForDeletion, isDeleteOne}) =>
{
    const {accounts, instance} = useMsal();
    const {notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const handleClose = () =>
    {
        setDeleteClicked(current => !current);
        isDeleteOne && setVisitorsForDeletion([]);
    }

    const handleVisitorDeletion = async () =>
    {
        const deletedVisitors = await visitorsDeletion(visitorsForDeletion.map(visitor => visitor._id), notificationDispatch, {accounts: accounts, instance: instance});

        if(deletedVisitors)
        {
            setVisitors(current => current.filter(visitor => !visitorsForDeletion.includes(visitor)));
            setVisitorsForDeletion([]);
            setDeleteClicked(current => !current);
        }
    }

    return (
        <div className={styles.delete_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={styles.delete_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <h2>{t("visitorDeleteScreen.deletionMessage")}</h2>
                <div className={styles.visitors_to_delete}>
                    {visitorsForDeletion.map(visitor => visitor.name).join(", ")}
                </div>
                <div className={styles.delete_controls}>
                    <Button btnSize="btn-sm" onClick={handleVisitorDeletion}>{t("visitorDeleteScreen.yes")}</Button>
                    <Button btnSize="btn-sm" btnStyle="delete-btn" onClick={() => handleClose()}>{t("visitorDeleteScreen.no")}</Button>
                </div>
            </div>
        </div>
    );
}

export default VisitorDeleteScreen;
