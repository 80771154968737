import React, {FC, ReactNode} from 'react';
import styles from "./css/Button.module.css";

interface IProps
{
    children?: ReactNode;
    href?: string;
    onClick?: () => void;
    btnSize?: string;
    disabled?: boolean;
    btnStyle?: string;
    selected?: boolean;
    fontSize?: string;
    className?: string;
}

const Button:FC<IProps> = ({children, href, onClick, btnSize = "btn-lg", disabled = false, btnStyle = "primary", selected = false, fontSize, className}) =>
{
    return (
        <a className={`${styles.btn} + ${styles[btnStyle]} + ${styles[btnSize]} + ${disabled ? styles.disabled : null} + ${selected ? styles.selected : null} + ${className}`} style={{fontSize: fontSize}} href={href} onClick={!disabled ? onClick : () => null}>{children}</a>
    );
}

export default Button;
