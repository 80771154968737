import React, {FC, useContext, useEffect, useState} from 'react';
import styles from './css/VisitorApprovalScreen.module.css';
import Button from "./Button";
import Context from "../helpers/Context";
import {checkVisitorStatus} from "../helpers/apiEndPoints";
import {useTranslation} from "react-i18next";
import {isAdmin, isHost} from "../helpers/checkers";
import Cookies from "js-cookie";

interface IProps
{
    branchId: string;
}

const VisitorApprovalScreen: FC<IProps> = ({branchId}) =>
{
    const [refreshTime, setRefreshTime] = useState<number>(30);
    const [status, setStatus] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [isVisitorDeleted, setIsVisitorDeleted] = useState<boolean>(false);

    const {notificationDispatch, groupsID} = useContext(Context);
    const {t} = useTranslation();

    useEffect(() =>
    {
        if(status && (isHost(groupsID) || isAdmin(groupsID))) Cookies.remove("visitorID", {path: `/branch/${branchId}`});
    }, [groupsID, status])

    useEffect(() =>
    {
        (async () =>
        {
            await handleCheckVisitorStatus();
        })();
    }, []);

    const handleCheckVisitorStatus = async () =>
    {
        console.log("i am called");

        const check = await checkVisitorStatus(notificationDispatch, setStatus, setIsRefreshing, setRefreshTime, setIsVisitorDeleted, branchId);
        const timer = setTimeout(() =>
        {
            setStatus(check);

            setIsRefreshing(false)
            setRefreshTime(30);

            clearTimeout(timer);
        }, 1500);
    }
    useEffect(() =>
    {
        if(refreshTime === 0)
        {
            (async () =>
            {
                    await handleCheckVisitorStatus();
            })();
        }
    }, [refreshTime]);

    useEffect(() => {
        const timer = setInterval(() =>
        {
            setRefreshTime(curr => curr - 1);
        }, 1000);

        if(status || isVisitorDeleted || isRefreshing) return clearInterval(timer);

        return () => clearInterval(timer);
    }, [status, isVisitorDeleted, isRefreshing]);

    return (
        <div className={styles.visitor_approval_main}>
            <h2>{isVisitorDeleted ? t("approvalScreen.errorTitle") :
                status ?
                    <div className={styles.approval_title}>
                        {t("approvalScreen.successTitle")}
                        <span>{t("approvalScreen.visitInstruction")}</span>
                    </div>
            : t("approvalScreen.waitingTitle")}</h2>

            <div className={styles.status}><b>{t("approvalScreen.status")}</b> <i>{isVisitorDeleted ? t("approvalScreen.errorStatus") : status ? t("approvalScreen.successStatus") : t("approvalScreen.waitingStatus")}</i></div>

            {!status && !isVisitorDeleted &&
                <div className={styles.refresh}>
                    <Button btnSize="btn-sm" onClick={async () => {await handleCheckVisitorStatus()}} disabled={isRefreshing}>{t("approvalScreen.refreshBtn")}</Button>
                    <span>{t("approvalScreen.autoRefresh")} {isRefreshing ? t("approvalScreen.refreshing") : refreshTime + ` ${t("approvalScreen.seconds")}`}</span>
                </div>
            }
        </div>
    );
}

export default VisitorApprovalScreen;
