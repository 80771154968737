import React from 'react';
import './css/Footer.module.css';
import {useTranslation} from "react-i18next";

function Footer()
{
    const {t} = useTranslation();

    return (
        <footer>
            <a href={t("app.privacyLink") as string} target="_blank">{t("app.privacy")}</a>
        </footer>
    );
}

export default Footer;