import React, {FC, useContext, useEffect, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import QRCode from "react-qr-code";
import styles from "./css/Branch.module.css";
import {branch, registrationKey} from "../helpers/types";
import VisitorForm from "./VisitorForm";
import VisitorsList from "./VisitorsList";
import {getBranchByID} from "../helpers/apiEndPoints";
import {isAdmin, isHost} from "../helpers/checkers";
import Context from "../helpers/Context";
import VisitorApprovalScreen from "./VisitorApprovalScreen";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
interface IProps
{
    registrationKey: registrationKey;
}

const Branch: FC<IProps> = ({registrationKey}) =>
{
    const {groupsID, notificationDispatch} = useContext(Context);
    const {t} = useTranslation();

    const [branch, setBranch] = useState<branch>({_id: "", address: "", city: "", name: "", postal_code: ""});
    const {id} = useParams<string>();
    const [searchParams] = useSearchParams();

    const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState<boolean>(false);

    useEffect(() =>
    {
        (async () =>
        {
            const branch = await getBranchByID(id, notificationDispatch);
            if(branch) setBranch(branch);
        })();
    }, [id]);

    return (
        <div className={styles.branch_container}>

            <Helmet>
                <title>{`${branch.address} | NVISIT`}</title>
            </Helmet>

            {(isHost(groupsID) || isAdmin(groupsID)) &&
                <VisitorsList branchId={id} isAdmin={isAdmin(groupsID)} branchAddress={branch.address} />
            }

            {(searchParams.get("key") !== null && !isRegistrationSuccessful && !Cookies.get("visitorID")) &&
                <div className={styles.visitor_registration}>
                    <h1>{t("branch.fillUpForm")}</h1>
                    <VisitorForm branchId={id} branchAddress={branch.address} setVisitors={() => null} isEdit={false} setIsRegistrationSuccessful={setIsRegistrationSuccessful} />
                </div>
            }

            {(isRegistrationSuccessful || Cookies.get("visitorID")) &&
                <VisitorApprovalScreen branchId={id as string} />
            }

            {searchParams.get("key") === null && !Cookies.get("visitorID") && branch.city && (!isAdmin(groupsID) || !isHost(groupsID)) &&
                <div className={styles.qr_code_container}>

                    <div className={styles.branch_info}>
                        <span>Netum {branch.city}</span>
                        <span>{branch.address}</span>
                        <span>{branch.city} {branch.postal_code}</span>
                    </div>

                    <h1>{t("branch.qrCodeInfo")}</h1>
                    <QRCode value={`${process.env.REACT_APP_WEB_URL}/branch/${id}?key=${registrationKey.key}`} />
                </div>
            }

        </div>
    );
}

export default Branch;
