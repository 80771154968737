import React from 'react';
import {useTranslation} from "react-i18next";
import '../i18n';
import {Helmet} from 'react-helmet-async'

function NotFound()
{
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("app.notFound")}</title>
            </Helmet>

            <h1>
                {t("app.notFound")}
            </h1>
        </>
    );
}

export default NotFound;