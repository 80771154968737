import React, {FC, useContext} from 'react';
import styles from './css/ControlPanel.module.css';
import VisitorsList from "./VisitorsList";
import BranchesList from "./BranchesList";
import Key from "./Key";
import {registrationKey} from "../helpers/types";
import {useNavigate, useParams} from "react-router-dom";
import {isAdmin} from "../helpers/checkers";
import Context from "../helpers/Context";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import Statistics from "./stats/Statistics";

interface IProps
{
    registrationKey: registrationKey;
}

const ControlPanel: FC<IProps> = ({registrationKey}) =>
{
    const {groupsID} = useContext(Context);

    const {option} = useParams();
    const navigate = useNavigate();

    const {t} = useTranslation();

    const renderSwitch = () =>
    {
        switch (option)
        {
            case "visitors":
                return <VisitorsList isControlPanel={true} isAdmin={isAdmin(groupsID)}/>
            case "branches":
                return <BranchesList registrationKey={registrationKey} isAdmin={isAdmin(groupsID)} />
            case "statistics":
                return <>{isAdmin(groupsID) ? <Statistics /> : <p>{t("app.accessError")}</p>}</>
            case "key":
                return <>{isAdmin(groupsID) ? <Key /> : <p>{t("app.accessError")}</p>}</>
            default:
                return <p>{t("app.wrongRoute")}</p>
        }
    }

    return (
        <div className={styles.control_panel_container}>

            <Helmet>
                <title>{`${t("app.controlPanel")} | NVISIT`}</title>
            </Helmet>

            <div className={styles.list_selection_container}>
                <a className={`${option === "visitors" ? styles.selected : null}`} onClick={() => navigate('/control-panel/visitors')}>{t("app.visitors")}</a>
                <a className={`${option === "branches" ? styles.selected : null}`} onClick={() => navigate('/control-panel/branches')}>{t("app.branches")}</a>
                {isAdmin(groupsID) && <a className={`${option === "statistics" ? styles.selected : null}`} onClick={() => navigate('/control-panel/statistics')}>{t("app.statistics")}</a>}
                {isAdmin(groupsID) && <a className={`${option === "key" ? styles.selected : null}`} onClick={() => navigate('/control-panel/key')}>{t("app.key")}</a>}
            </div>

            <>
                {renderSwitch()}
            </>
        </div>
    );
}

export default ControlPanel;
