import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import absoluteStyles from "./css/AbsoluteWindowStyles.module.css";
import VisitorForm from "./VisitorForm";
import styles from "./css/HostAdminVisitorFormModal.module.css";
import {useTranslation} from "react-i18next";
import Button from "./Button";
import {Icons} from "../helpers/Icons";
import {visitor} from "../helpers/types";

interface IProps
{
    branchID: string | undefined;
    branchAddress: string | undefined;
    isRegistrationSuccessful: boolean;
    setIsRegistrationSuccessful: Dispatch<SetStateAction<boolean>>;
    setIsAddVisitorClicked: Dispatch<SetStateAction<boolean>>;
    setVisitors: Dispatch<SetStateAction<visitor[]>>;
}

const HostAdminVisitorFormModal: FC<IProps> = ({branchID, branchAddress, setIsRegistrationSuccessful, setVisitors, setIsAddVisitorClicked, isRegistrationSuccessful}) =>
{
    const {t} = useTranslation();

    useEffect(() =>
    {
        if(isRegistrationSuccessful) handleClose();
    }, [isRegistrationSuccessful])

    const handleClose = () =>
    {
        setIsAddVisitorClicked(current => !current)
    }

    return (
        <div className={absoluteStyles.window_container} onClick={() => handleClose()} onTouchEnd={() => handleClose()}>
            <div className={absoluteStyles.window_container_content} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
                <div className={absoluteStyles.info_content}>

                    <div className={styles.container}>
                        <div className={styles.title_container}><h1>{t("branch.fillUpForm")}</h1> <span className={styles.close} onClick={handleClose}>{Icons.close}</span></div>
                        <VisitorForm branchId={branchID} branchAddress={branchAddress} isEdit={false} setVisitors={setVisitors} setIsRegistrationSuccessful={setIsRegistrationSuccessful} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HostAdminVisitorFormModal;
