import React, {Dispatch, FC, FormEvent, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from './css/Form.module.css';
import {visitor} from "../helpers/types";
import Context from "../helpers/Context";
import {formValidation} from "../helpers/helperFunctions";
import PageSortContext from "../helpers/PageSortContext";
import {v4} from "uuid";
import {useTranslation} from "react-i18next";
import {t} from "i18next";
import {visitorVerificationForm} from "../helpers/apiEndPoints";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    setVerifiedClicked: Dispatch<SetStateAction<boolean>>;
    visitorIndex: number;
    visitors: visitor[];
    setVisitors: Dispatch<SetStateAction<visitor[]>>;
    setFilteredVisitors: Dispatch<SetStateAction<visitor[]>>;
}

const VerifiedForm:FC<IProps> = ({setVerifiedClicked, visitorIndex,visitors, setVisitors, setFilteredVisitors}) =>
{
    const {accounts, instance} = useMsal();
    const {notificationDispatch} = useContext(Context);

    const [cardNumber, setCardNumber] = useState<string>("");
    const [checkedDocument, setCheckedDocument] = useState<string>("");

    const {t} = useTranslation();

    const handleSubmit = async () =>
    {
        const verificationForm = await visitorVerificationForm(visitors[visitorIndex]._id,
            {cardNumber: cardNumber?.trim().replace(/\s\s+/g, ' ') ?? "", verificationMethod: checkedDocument?.trim().replace(/\s\s+/g, ' ') ?? ""},
            {accounts, instance}, notificationDispatch);

        if(verificationForm)
        {
            setVerifiedClicked(false);

            setVisitors(prevState =>
            {
                prevState[visitorIndex].verified_status.status = verificationForm.verified_status.status;
                prevState[visitorIndex].verified_status.visitorCardNumber = verificationForm.verified_status.visitorCardNumber;
                prevState[visitorIndex].verified_status.verificationMethod = verificationForm.verified_status.verificationMethod;
                return [...prevState];
            });

            setFilteredVisitors(prevState =>
            {
                prevState[visitorIndex].verified_status.status = verificationForm.verified_status.status;
                prevState[visitorIndex].verified_status.visitorCardNumber = verificationForm.verified_status.visitorCardNumber;
                prevState[visitorIndex].verified_status.verificationMethod = verificationForm.verified_status.verificationMethod;
                return [...prevState];
            });
        }
    }

    return (
        <div className={styles.edit_form_container} onClick={() => setVerifiedClicked(current => !current)} onTouchEnd={() => setVerifiedClicked(current => !current)}>
            <div className={styles.edit_form} onClick={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()}>
            <form className={`${styles.form} + ${styles.form_edit}`}
                  onSubmit={e => formValidation(e, [{inputName: "Card number", input: cardNumber, skip: false}, {inputName: "Verification method", input: checkedDocument, skip: false}], notificationDispatch, handleSubmit)}>
                <label>
                    {t("verificationForm.cardNumber")}
                    <input type="text" required={true} onChange={e => setCardNumber(e.target.value)} />
                </label>
                <span>{t("verificationForm.verifyMethod")}</span>
                <label className={styles.document_check}>
                    <input type="checkbox"
                           checked={checkedDocument === "passport"}
                           onChange={() => setCheckedDocument("passport")}/>
                    {t("verificationForm.passport")}
                </label>

                <label className={styles.document_check}>
                    <input type="checkbox"
                           checked={checkedDocument === "driverLicense"}
                           onChange={() => setCheckedDocument("driverLicense")}/>
                    {t("verificationForm.driverLicense")}
                </label>

                <label className={styles.document_check}>
                    <input type="checkbox"
                           checked={checkedDocument === "id"}
                           onChange={() => setCheckedDocument("id")}/>
                    {t("verificationForm.id")}
                </label>

                <label className={styles.document_check}>
                    <input type="checkbox"
                           checked={checkedDocument === "knownPerson"}
                           onChange={() => setCheckedDocument("knownPerson")}/>
                    {t("verificationForm.knownPerson")}
                </label>

                <label className={styles.document_check}>
                    {t("verificationForm.other")}
                    <input type="text" onChange={e => setCheckedDocument(e.target.value)} />
                </label>

                <input type="submit" value={t("verificationForm.validateBtn") as string} />
            </form>
        </div>
    </div>
    );
}

export default VerifiedForm;
