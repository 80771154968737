import React, {FC} from 'react';
import VisitorsAllBranches from "./VisitorsAllBranches";
import VisitorsPerBranch from "./VisitorsPerBranch";
import styles from './css/Statistics.module.css';

interface IProps
{

}


const Statistics: FC<IProps> = ({}) =>
{
    return (
        <div className={styles.statistics_container}>
            <VisitorsAllBranches />
            <VisitorsPerBranch />
        </div>
    );
}

export default Statistics;
