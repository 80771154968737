import {Dispatch, SetStateAction} from "react";
import {branch, notificationDispatch, registrationKey, statsAllBranches, visitor} from "./types";
import {loginRequest} from "../authConfig";
import {AccountInfo} from "@azure/msal-common";
import {IPublicClientApplication} from "@azure/msal-browser";
import {v4} from "uuid";
import Cookies from "js-cookie";
import {t} from "i18next";
import {browserName, deviceType, mobileModel, osName} from 'react-device-detect';
import {isAdmin, isHost} from "./checkers";

export const RequestAccessToken = async (accounts: AccountInfo[], instance: IPublicClientApplication) =>
{
    if(accounts.length && instance.getActiveAccount())
    {
        const account = instance.getActiveAccount();

        const request =
            {
                ...loginRequest,
                account: account !== null ? account : undefined
            };

        return instance.acquireTokenSilent(request).then(response =>
        {
            /*            console.log(response)*/
            return response.accessToken;
        }).catch(e =>
        {
            console.log("Error token silent")
            console.log(e)
            instance.acquireTokenRedirect(request).catch(e =>
            {
                console.log("Error token redirect")
                console.log(e)
            })
            return "";
        })

    } else return "";
}

export const getBranchByID = async (branchID: string | undefined, notificationDispatch: Dispatch<any>): Promise<branch| void> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/branch/${branchID}`)
            .then(response => response.json())
            .then(data =>
            {
                if(data.error)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEndMessages.${data.loc}`) : data.error
                            }
                    });
                    return;
                }

                return data;

            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })

        return;
    }
}

export const getBranches = async (notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<branch[] | void> =>
{
    try 
    {
        const {accounts, instance} = token;
        
        return await fetch(`${process.env.REACT_APP_API_URL}/branch`,
            {
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                if(data.error)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: t(`backEndMessages.${data.loc}`)
                            }
                    });
                    return;
                }

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        });
        
        return;
    }
}

export const deleteBranches = async (id: string, notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}):Promise<boolean | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/branch/${id}`,
            {
                method: "DELETE",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.branchDelete") + ` ${data.branchId} ` + t(`backEndMessages.${data.loc}`)
                        }
                });

                return true;
            });
    }
    catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
        return;
    }
}

export const getAllVisitorsOrBranchVisitors = async (url: string, notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<visitor[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(url,
            {
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                if(data.message)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: t(`backEndMessages.${data.loc}`)
                            }
                    });

                    return;
                }

                return data;

            });

    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
        return;
    }
}


export const unverifyVisitor = async (visitorID: string, notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<visitor | void> =>
{
    try
    {
       const {accounts, instance} = token;

       return await fetch(`${process.env.REACT_APP_API_URL}/visitor/unverify/${visitorID}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                if(!data.isVerified)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "warning",
                                message: t("backEndMessages.visitor") + ` ${data.visitorName} ` + t(`backEndMessages.${data.loc}`)
                            }
                    });
                    return;
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.visitor") + ` ${data.visitorName} ` + t(`backEndMessages.${data.loc}`)
                        }
                })

                return data.visitor;
            });
    }
    catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
        return;
    }
}

export const modifyVisitor = async (visitor: {id: string, name: string, organization: string, hostName: string}, notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<visitor | void> =>
{
    try
    {
        const {accounts, instance} = token;
        const {id, name, organization, hostName} = visitor;

        return await fetch(`${process.env.REACT_APP_API_URL}/visitor/${id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name,
                    "organization": organization,
                    "host_name": hostName
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.visitor") + ` ${data.visitorName} ` + t(`backEndMessages.${data.loc}`)
                        }
                })

                return data.visitor;
            })
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        });
        return ;
    }
}

export const addNewVisitor =  async (visitor: {name: string, organization: string, hostName: string, visitingDate: Date, verifiedStatus: {status: boolean, visitorCardNumber: string, verificationMethod: string},
    groupsID: string[], branch: {branch_id: string, branchAddress: string}}, key: string, notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<visitor | void> =>
{
    try
    {
        const {accounts, instance} = token;
        const {name, organization, hostName, branch, visitingDate, verifiedStatus, groupsID} = visitor;

        return await fetch(`${process.env.REACT_APP_API_URL}/visitor?key=${key}`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name,
                    "organization": organization,
                    "host_name": hostName,
                    "branch": branch,
                    "visitor_date": visitingDate,
                    "verified_status": verifiedStatus,
                    "groupsID": groupsID
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                if(data.message)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "success",
                                message: t(`backEndMessages.${data.loc}`)
                            }
                    })

                    Cookies.set("visitorID", data.visitor._id, {secure: true, path: `/branch/${branch.branch_id}`});

                    return data.visitor;
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "error",
                            message: t(`backEndMessages.${data.loc}`)
                        }
                })
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
        return;
    }
}

export const visitorVerificationForm = async (visitorID: string, verificationInfo: {cardNumber: string, verificationMethod: string}, token: {accounts: AccountInfo[], instance: IPublicClientApplication}, notificationDispatch: Dispatch<any>): Promise<visitor | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/visitor/verify/${visitorID}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "visitorCardNumber": verificationInfo.cardNumber,
                    "verificationMethod": verificationInfo.verificationMethod
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                if(data.isVerified)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "warning",
                                message: t("backEndMessages.visitor") + ` ${data.visitorName} ` + t(`backEndMessages.${data.loc}`)
                            }
                    })

                    return;
                }

                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.visitor") + ` ${data.visitorName} ` + t(`backEndMessages.${data.loc}`)
                        }
                })

                return data.visitor;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        });

        return;
    }
}

export const visitorsDeletion = async (visitorsForDeletionIDs: string[], notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<boolean | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/visitor/`,
            {
                method: "DELETE",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "visitors_id": visitorsForDeletionIDs
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.visitorsMultipleDeletion") + ` ${data.deletedVisitorsID} ` + t(`backEndMessages.${data.loc}`)
                        }
                })

                return true;
            });
    }
    catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        });
        return;
    }
}

export const getKey = async (notificationDispatch: Dispatch<any>): Promise<registrationKey | void> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/key`)
            .then(response => response.json())
            .then(data =>
            {
                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
        return;
    }
}

export const handleKeyChange = async (key: registrationKey, notificationDispatch: Dispatch<any>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<registrationKey | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/key/${key._id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "key": key.key,
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t(`backEndMessages.${data.loc}`)
                        }
                })

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
        return;
    }
}

export const checkVisitorStatus = async (notificationDispatch: Dispatch<any>, setStatus: Dispatch<SetStateAction<boolean>>, setIsRefreshing: Dispatch<SetStateAction<boolean>>, setRefreshTime: Dispatch<SetStateAction<number>>, setIsVisitorDeleted: Dispatch<SetStateAction<boolean>>, branchId: string) =>
{
    try
    {
        setIsRefreshing(true);

        return await fetch(`${process.env.REACT_APP_API_URL}/visitor/verifstatus/${Cookies.get("visitorID")}`)
            .then(response => response.json())
            .then(data =>
            {
                if(data instanceof Object)
                {
                    Cookies.set("visitorID", Cookies.get("visitorID")!.toString(), {expires: new Date(new Date().getTime() + 2 * 60 * 1000), secure: true, path: `/branch/${branchId}`});
                    return setIsVisitorDeleted(true)
                }

                if(data === true)
                {
                    Cookies.set("visitorID", Cookies.get("visitorID")!.toString(), {expires: new Date(new Date().getTime() + 5 * 60 * 1000), secure: true, path: `/branch/${branchId}`});
                }

                return data;

            });

    } catch (err)
    {
        return notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: err
                }
        })
    }
}

export const getAllBranchesStats = async (notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<statsAllBranches[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/stats/`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEndMessages.${data.loc}`) : data.error
                            }
                    })

                    return;
                }

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        });

        return ;
    }
}

export const modifyBranch = async (branch: {id: string, name: string, address: string, city: string, postalCode: string}, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<branch | void> =>
{
    try
    {
        const {id, name, address, city, postalCode} = branch;
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/branch/${id}`,
            {
                method: 'PATCH',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name,
                    "address": address,
                    "city": city,
                    "postal_code": postalCode,
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.branch") + ` ${data.branchName} ` + t(`backEndMessages.${data.loc}`)
                        }
                })

                return data.branch;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        });
        return;
    }
}

export const addNewBranch = async (branch: {id: string, name: string, address: string, city: string, postalCode: string}, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<branch | void> =>
{
    try
    {
        const {name, address, city, postalCode} = branch;
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/branch/`,
            {
                method: 'POST',
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "name": name,
                    "address": address,
                    "city": city,
                    "postal_code": postalCode,
                })
            })
            .then(response => response.json())
            .then(data =>
            {
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: t("backEndMessages.branch") + ` ${data.branchName} ` + t(`backEndMessages.${data.loc}`)
                        }
                });

                return data.branch;
            });

    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        });

        return;
    }
}

export const getPerBranchStats = async (branchID: string, notificationDispatch: Dispatch<notificationDispatch>, token: {accounts: AccountInfo[], instance: IPublicClientApplication}): Promise<statsAllBranches[] | void> =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/stats/${branchID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`}
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEndMessages.${data.loc}`) : data.error
                            }
                    })

                    return ;
                }

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })
        return ;
    }
}

export const savePushNotificationSubscription = async (subscription: PushSubscription, token: {accounts: AccountInfo[], instance: IPublicClientApplication}, notificationDispatch: Dispatch<notificationDispatch>) =>
{
    try
    {
        const {accounts, instance} = token;

        return await fetch(`${process.env.REACT_APP_API_URL}/notification/subscribe`,
            {
                method: "POST",
                headers: {'Authorization': `Bearer ${await RequestAccessToken(accounts, instance)}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    subscription: subscription,
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName
                })
            })
            .then(response => response.json()).then(data =>
            {
                if(data.error)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })

                    return ;
                }

                return data;
            });
    } catch (err)
    {
        if(notificationDispatch)
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: (err as string)
                    }
            })

            return ;
        }

        console.log(err);

        return ;
    }
}

export const renewUserSubscription = async (subscription: PushSubscription, id: string, user: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/`,
            {
                method: "PATCH",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: id,
                    subscription: subscription,
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    user: user
                })
            })
            .then(response => response.json()).then(data => data);
    } catch (err)
    {
        return console.log(err);
    }
}

export const deletePushNotificationSubscription = async (id: string, user: string) =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/notification/${id}`,
            {
                method: "DELETE",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    device: deviceType === "browser" ? osName : mobileModel,
                    browser: browserName,
                    user: user
                })
            })
            .then(response => response.json()).then(data =>
            {
                console.log(data);
            });
    } catch (err)
    {
        console.log(err);
    }
}
