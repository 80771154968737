import {createContext, Dispatch, FC, ReactNode, SetStateAction, useReducer, useState} from "react";
import {notification, registrationKey} from "./types";

interface IContext
{
    groupsID: string[];
    setGroupsID: Dispatch<SetStateAction<string[]>>;

    notificationState: notification[];
    notificationDispatch: any;

    key: registrationKey;
    setKey: Dispatch<SetStateAction<registrationKey>>

    hideNotificationPrompt: boolean;
    setHideNotificationPrompt: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<IContext>({
    groupsID: [],
    hideNotificationPrompt: false,
    setHideNotificationPrompt(value: ((prevState: boolean) => boolean) | boolean): void {
    },
    key: {_id: "", key: ""},
    notificationDispatch: undefined,
    notificationState: [],
    setGroupsID(value: ((prevState: string[]) => string[]) | string[]): void {
    },
    setKey(value: ((prevState: registrationKey) => registrationKey) | registrationKey): void {
    }
});

interface IProps
{
    children: ReactNode;
}

export const ContextProvider: FC<IProps> = ({children}) =>
{
    const [groupsID, setGroupsID] = useState<string[]>([]);
    const [notificationState, notificationDispatch] = useReducer((state: notification[], action: any) =>
    {
        switch (action.type)
        {
            case "ADD_NOTIFICATION":
                return [...state, {...action.payload}];
            case "REMOVE_NOTIFICATION":
                return state.filter(item => item.id !== action.id);
            default:
                return state;
        }
    }, []);

    const [key, setKey] = useState<registrationKey>({_id: "", key: ""});

    const [hideNotificationPrompt, setHideNotificationPrompt] = useState<boolean>(false);

    return(
        <Context.Provider value={{groupsID, setGroupsID, notificationState, notificationDispatch, key, setKey, hideNotificationPrompt, setHideNotificationPrompt}}>
            {children}
        </Context.Provider>
    )
}

export default Context;
