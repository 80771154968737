import React, {FC, useContext, useEffect} from 'react';
import styles from './css/Form.module.css';
import Context from "../helpers/Context";
import {getKey, handleKeyChange} from "../helpers/apiEndPoints";
import {v4} from "uuid";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

const Key:FC = () =>
{
    const {accounts, instance} = useMsal()
    const {notificationDispatch, key, setKey} = useContext(Context);
    const {t} = useTranslation();

    useEffect(() =>
    {
        (async () =>
        {
            const key = await getKey(notificationDispatch);
            if(key) setKey(key);
        })();
    }, [])

    const handleKeyChangeEvent = async (e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
    {
        e.preventDefault();

        const changedKey = await handleKeyChange(key, notificationDispatch, {accounts: accounts, instance: instance});
        if(changedKey) setKey(changedKey);
    }

    return (
        <form className={styles.form}>
            <h3>{t("app.key")}</h3>

            <input type="text" value={key.key} onChange={e => setKey({_id: key._id, key: e.target.value})} />
            <input type="submit" value={t("lists.changeKeyBtn") as string} onClick={async (e) => {await handleKeyChangeEvent(e)}} />
        </form>
    );
}

export default Key;
