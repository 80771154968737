import React, {FC, useContext, useEffect, useState} from 'react';
import {branch, registrationKey} from "../helpers/types";
import {deleteBranches, getBranches} from "../helpers/apiEndPoints";
import styles from "./css/List.module.css";
import {Icons} from "../helpers/Icons";
import BranchForm from "./BranchForm";
import QRCode from "react-qr-code";
import Button from "./Button";
import {handleSorting, onImageDownload} from "../helpers/helperFunctions";
import Context from "../helpers/Context";
import PageSort from "./PageSort";
import PageSortContext from "../helpers/PageSortContext";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    registrationKey: registrationKey;
    isAdmin?: boolean;
}

const BranchesList: FC<IProps> = ({registrationKey, isAdmin}) =>
{
    const {accounts, instance} = useMsal();

    const {notificationDispatch} = useContext(Context);
    const {itemsPerPage, setNumOfPages} = useContext(PageSortContext);

    const [branches, setBranches] = useState<branch[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const filteredBranches = searchQuery !== "" ? branches.filter(branch => branch.name.toLowerCase().includes(searchQuery)) : branches;

    const [editClicked, setEditClicked] = useState<boolean>(false);
    const [isAddClicked, setIsAddClicked] = useState<boolean>(false);
    const [branchIndex, setBranchIndex] = useState<number>(0);

    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [sortField, setSortField] = useState<string>("");

    const {t} = useTranslation();

    useEffect(() =>
    {
        (async () =>
        {
            const allBranches = await getBranches(notificationDispatch, {accounts: accounts, instance: instance});

            if(allBranches)
            {
                setBranches(allBranches);

                if(allBranches.length > itemsPerPage)
                {
                    let calculateNumOfPages = allBranches.length / itemsPerPage;

                    if(calculateNumOfPages % 1 !== 0 || calculateNumOfPages === 0) calculateNumOfPages = Math.trunc(calculateNumOfPages + 1);

                    setNumOfPages(calculateNumOfPages);
                }
            }
        })();
    }, []);

    const handleBranchDelete = async (branchID: string) =>
    {
        const deletedBranch = await deleteBranches(branchID, notificationDispatch, {accounts: accounts, instance: instance});
        if(deletedBranch) setBranches(current => current.filter(branch => branch._id !== branchID));
    }

    return (
        <div className={styles.list_container}>
            <div className={styles.branches_title}>
                <h3>{t("app.branches")}</h3>
                {isAdmin &&
                <div className={styles.add_branch}>
                    <Button btnSize="btn-sm" onClick={() => setIsAddClicked(current => !current)}>{t("branchesList.addBtn")}</Button>
                    {isAddClicked &&
                        <div className={styles.edit_form_container} onClick={() => setIsAddClicked(current => !current)}>
                            <div className={styles.edit_form} onClick={e => e.stopPropagation()}>
                                <BranchForm branches={branches}
                                            setBranches={setBranches}
                                            setEditClicked={setIsAddClicked}
                                            branchIndex={0}
                                            isEdit={false} />
                            </div>
                        </div>
                    }
                </div>
                }
            </div>
            <div className={styles.table_controls}>
                <div className={styles.search}>
                    <input type="text" value={searchQuery} placeholder={t("lists.search") as string} onChange={e => setSearchQuery(e.target.value)}/>
                </div>

                <div className={styles.page_sort_controls}>
                    <PageSort arrayToModify={filteredBranches} searchQuery={searchQuery} />
                </div>
            </div>
            <table>
                <thead>
                <tr>
                    <th>
                        <div onClick={() =>
                        {
                            handleSorting(branches, "name", sortOrder, setBranches, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("branchesList.name")}{sortField === "name" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() =>
                        {
                            handleSorting(branches, "city", sortOrder, setBranches, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("branchesList.city")}{sortField === "city" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() =>
                        {
                            handleSorting(branches, "address", sortOrder, setBranches, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("branchesList.address")}{sortField === "address" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>
                        <div onClick={() =>
                        {
                            handleSorting(branches, "postal_code", sortOrder, setBranches, setSortField);
                            setSortOrder(current => current === "asc" ? "desc" : "asc");
                        }}>
                            {t("branchesList.postalCode")}{sortField === "postal_code" ? sortOrder === "asc" ? Icons.descArrow : Icons.ascArrow : Icons.unsorted}
                        </div>
                    </th>
                    <th>{t("branchesList.qrCode")}</th>
                    {isAdmin && <th>{t("lists.action")}</th>}
                </tr>
                </thead>
                <tbody>
                {Array.isArray(filteredBranches) && filteredBranches.map((branch, index) =>
                    <tr key={branch._id}>
                        <td data-label={t("branchesList.name")}>{branch.name}</td>
                        <td data-label={t("branchesList.city")}>{branch.city}</td>
                        <td data-label={t("branchesList.address")}>{branch.address}</td>
                        <td data-label={t("branchesList.postalCode")}>{branch.postal_code}</td>
                        <td data-label={t("branchesList.qrCode")}>
                            <div className={styles.qr_code}>
                                <QRCode id={`QRCode${branch._id}`} value={`${process.env.REACT_APP_WEB_URL}/branch/${branch._id}?key=${registrationKey.key}`} />
                                <button onClick={() => onImageDownload(branch._id, branch.address)}>{t("branchesList.downloadQR")}</button>
                            </div>
                        </td>
                        {isAdmin &&
                            <td data-label={t("lists.action")}>
                                <div className={styles.admin_controls}>
                                    <Button btnSize="btn-sm" onClick={() => {setEditClicked(current => !current); setBranchIndex(index);}}>{t("lists.edit")}{Icons.edit}</Button>
                                    <Button btnSize="btn-sm" btnStyle="delete-btn" onClick={async () => {await handleBranchDelete(branch._id)}}>{t("lists.delete")}{Icons.delete}</Button>
                                </div>
                            </td>
                        }
                    </tr>
                )}
                </tbody>
            </table>
            {editClicked &&
                <BranchForm
                    isEdit={true}
                    branches={filteredBranches}
                    setBranches={setBranches}
                    branchIndex={branchIndex}
                    setEditClicked={setEditClicked} />
            }
        </div>
    );
}

export default BranchesList;
