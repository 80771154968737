import React from 'react';
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import styles from './css/SignInOutButtons.module.css';
import Button from "./Button";

function SignOutButton()
{
    const { instance , accounts} = useMsal();
    const {t} = useTranslation();

    const handleLogout = () =>
    {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/"
        });
    }

    return (
        <Button className={`${styles.btn}`} btnStyle="secondary" btnSize="btn-sm" onClick={handleLogout} >{t("app.signOut")}</Button>
    );
}

export default SignOutButton;
