import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import {ContextProvider} from "./helpers/Context";
import NotificationProvider from "./components/Notifications/NotificationProvider";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// @ts-ignore
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ContextProvider>
          <NotificationProvider>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
          </NotificationProvider>
      </ContextProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
