import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {useTranslation} from "react-i18next";
import styles from './css/SignInOutButtons.module.css';
import Button from "./Button";

export const SignInButton = () =>
{
    const { instance } = useMsal();

    const {t} = useTranslation();

    const handleLogin = () =>
    {
            instance.loginRedirect(loginRequest).catch(e =>
            {
                console.log(e);
            });
    }

    return (
        <Button className={`${styles.btn}`} btnStyle="secondary" btnSize="btn-sm" onClick={handleLogin}>{t("app.signIn")}</Button>
    );
}
