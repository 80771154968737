import React, {useEffect, useContext} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import NavBar from "./components/NavBar";
import Branches from "./components/Branches";
import Branch from "./components/Branch";
import ControlPanel from "./components/ControlPanel";

import "./index.css";
import {getKey} from "./helpers/apiEndPoints";
import Footer from "./components/Footer";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
    useMsalAuthentication
} from "@azure/msal-react";
import {isAdmin, isHost} from "./helpers/checkers";
import Context from "./helpers/Context";
import {PageSortProvider} from "./helpers/PageSortContext";
import {loginRequest} from "./authConfig";
import NotFound from "./components/NotFound";

import './i18n';
import {useTranslation} from "react-i18next";
import {HelmetProvider} from "react-helmet-async";
import {InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";
import Button from "./components/Button";
import {handleNotifications} from "./helpers/helperFunctions";
import {get} from "idb-keyval";

function App()
{
    const {groupsID, setGroupsID, notificationDispatch, key, setKey, setHideNotificationPrompt, hideNotificationPrompt } = useContext(Context);

    const { instance, accounts } = useMsal();
    const {login, error} = useMsalAuthentication(InteractionType.Silent, loginRequest);

    const isAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();

    useEffect(() =>
    {
        (async () =>
        {
            await instance.initialize().then(async () =>
            {
                if(instance.getAllAccounts().length)
                {
                    const loginHint = instance.getAllAccounts().filter(account => account.tenantId === process.env.REACT_APP_TENANT_ID)[0].idTokenClaims?.login_hint;
                    const accountHomeID = instance.getAllAccounts().filter(account => account.tenantId === process.env.REACT_APP_TENANT_ID)[0].homeAccountId;

                    if(loginHint)
                    {
                        await instance.ssoSilent({...loginRequest, loginHint: loginHint})
                            .catch(e =>
                            {
                                if(e instanceof InteractionRequiredAuthError)
                                {
                                    instance.handleRedirectPromise().then(authResult=>
                                    {
                                        const account = instance.getActiveAccount();
                                        if(!account)
                                        {
                                            instance.loginRedirect();
                                        }
                                    }).catch(err=>{
                                        console.log(err);
                                    });
                                }
                            });

                        if(accountHomeID) instance.setActiveAccount(instance.getAccountByHomeId(accountHomeID));
                    }
                }
            });
        })();
    }, []);

    useEffect(() =>
    {
        if(isAuthenticated)
        {
            if(accounts[0].idTokenClaims !== undefined && Array.isArray(accounts[0].idTokenClaims.groups)) setGroupsID(accounts[0].idTokenClaims.groups);
        }

   }, [isAuthenticated]);

    useEffect(() =>
    {
        (async () =>
        {
            const key = await getKey(notificationDispatch);
            if(key) setKey(key);
        })();
    }, []);

    useEffect(() =>
    {
        (async () =>
        {
            const endpoint =  await get("subscriptionEndpoint");
            setHideNotificationPrompt(endpoint !== undefined || Notification.permission === "granted" || Notification.permission === "denied");
        })();
    }, []);

  return (
      <Router>
          <NavBar />
          <HelmetProvider>
              <div className="main-container">
                  {!hideNotificationPrompt && isAdmin(groupsID) ?
                      <div className="notification_button"><Button onClick={async () => await handleNotifications({accounts: accounts, instance: instance}, notificationDispatch, setHideNotificationPrompt)}>{t("app.notificationPermission")}</Button></div> : null}
                <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" element={
                            <>
                                <AuthenticatedTemplate><Branches /></AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                    <p>{t("app.authWarning")}</p>
                                </UnauthenticatedTemplate>
                            </>} />
                    <Route path="/branch/:id" element={<PageSortProvider><Branch registrationKey={key} /></PageSortProvider>} />
                        <Route path="/control-panel/" element={<Navigate to="/control-panel/visitors" replace={true}/>} />
                        <Route path="/control-panel/:option" element={
                            <>
                                <AuthenticatedTemplate>
                                    {(isAdmin(groupsID) || isHost(groupsID)) ?
                                        <PageSortProvider><ControlPanel registrationKey={key} /></PageSortProvider>
                                        : <p>{t("app.accessError")}</p>}
                                </AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                    <p>{t("app.authWarning")}</p>
                                </UnauthenticatedTemplate>
                            </>
                        } />
                </Routes>
              </div>
          </HelmetProvider>
          <Footer />
      </Router>
  );
}

export default App;
