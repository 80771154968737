import React, {useContext, useEffect, useState} from 'react';
import styles from './css/Branches.module.css';
import {branch} from "../helpers/types";
import {getBranches} from "../helpers/apiEndPoints";
import Context from "../helpers/Context";
import {set} from "idb-keyval";
import {useMsal} from "@azure/msal-react";

function Branches()
{
    const {accounts, instance} = useMsal();
    const {notificationDispatch} = useContext(Context);

    const [branches, setBranches] = useState<branch[]>([]);

    useEffect(() =>
    {
        (async () =>
        {
            const branches = await getBranches(notificationDispatch, {accounts: accounts, instance: instance});
            if(branches) setBranches(branches);
        })();
    }, []);

    return (
        <div className={styles.container}>
            {Array.isArray(branches) && branches.map(branch =>
                <a href={`/branch/${branch._id}`} className={styles.branch_card} key={branch._id}>
                    <div className={styles.branch_info}>
                        <span>Netum {branch.city}</span>
                        <span>{branch.address}</span>
                        <span>{branch.city} {branch.postal_code}</span>
                    </div>
                </a>
            )}
        </div>
    );
}

export default Branches;
