import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import styles from './css/Form.module.css';
import {useSearchParams} from "react-router-dom";
import {branch, visitor} from "../helpers/types";
import Context from "../helpers/Context";
import {formValidation} from "../helpers/helperFunctions";
import {v4} from "uuid";
import Cookies from 'js-cookie';
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import {isAdmin, isHost} from "../helpers/checkers";
import {FormDatePicker} from "./DatePickers";
import {addNewVisitor, getBranches, modifyVisitor} from "../helpers/apiEndPoints";

interface IProps
{
    isEdit: boolean; // Defines if the form used as an edit form or not. If it is used as an edit form, you must pass other props except branchID
    setEditClicked?: Dispatch<SetStateAction<boolean>>;
    branchId?: string;
    branchAddress?: string;
    visitorIndex?: number;
    visitors?: visitor[];
    setVisitors: Dispatch<SetStateAction<visitor[]>>;
    setFilteredVisitors?: Dispatch<SetStateAction<visitor[]>>;
    setIsRegistrationSuccessful?: Dispatch<SetStateAction<boolean>>;
}

const VisitorForm:FC<IProps> = ({isEdit, setEditClicked, branchId, branchAddress, visitorIndex, visitors, setVisitors, setFilteredVisitors, setIsRegistrationSuccessful}) =>
{
    const {accounts, instance } = useMsal();
    const {notificationDispatch, groupsID} = useContext(Context);
    const {t} = useTranslation();

    const [name, setName] = useState<string | undefined>(isEdit ? visitors![visitorIndex!].name : "");
    const [organization, setOrganization] = useState<string | undefined>(isEdit ? visitors![visitorIndex!].organization : "");
    const [hostName, setHostName] = useState<string | undefined>("");
    const [cardNumber, setCardNumber] = useState<string>("");
    const [checkedDocument, setCheckedDocument] = useState<string>("");

    const [searchParams] = useSearchParams();

    const [visitingDate, setVisitingDate] = useState<Date>(new Date(Date.now()));
    const [allBranches, setAllBranches] = useState<branch[]>([]);

    const [selectedBranch, setSelectedBranch] =
        useState<{_id: string | undefined, address: string | undefined}>({_id: branchId, address: branchAddress});

    useEffect(() =>
    {
        setSelectedBranch({_id: branchId, address: branchAddress})
    }, [branchId, branchAddress])

    useEffect(() =>
    {
        if(selectedBranch._id === undefined || selectedBranch.address === undefined)
        {
            (async () =>
            {
               const branches = await getBranches(notificationDispatch, {accounts: accounts, instance: instance});
               if(branches) setAllBranches(branches);
            })();
        }
    }, [branchId, branchAddress])

    useEffect(() =>
    {
        setHostName(isEdit ? visitors![visitorIndex!].host_name : (isHost(groupsID) || isAdmin(groupsID)) ? accounts[0].name : "")
    }, [groupsID, isEdit]);

    const handleSubmit = async () =>
    {
        const visitor = {name: name?.trim().replace(/\s\s+/g, ' ') ?? "",
        organization: organization?.trim().replace(/\s\s+/g, ' ') ?? "", hostName: hostName?.trim().replace(/\s\s+/g, ' ') ?? "",
        branch: {branch_id: selectedBranch._id ?? "", branchAddress: selectedBranch.address ?? ""}, visitingDate: visitingDate, verifiedStatus: {status: (isHost(groupsID) || isAdmin(groupsID)), visitorCardNumber: cardNumber, verificationMethod: checkedDocument},
        groupsID: groupsID};

        console.log(selectedBranch)


        if(isEdit)
        {
            const modifiedVisitor = await modifyVisitor({id: visitors![visitorIndex!]._id, name: visitor.name ?? "", organization: visitor.organization ?? "", hostName: visitor.hostName ?? ""}, notificationDispatch, {accounts: accounts, instance: instance});
            if(modifiedVisitor)
            {
                setEditClicked!(false);

                setVisitors(prevState =>
                {
                    prevState[visitorIndex!].name = modifiedVisitor.name;
                    prevState[visitorIndex!].organization = modifiedVisitor.organization;
                    prevState[visitorIndex!].host_name = modifiedVisitor.host_name;
                    return [...prevState];
                });

                setFilteredVisitors!(prevState =>
                {
                    prevState[visitorIndex!].name = modifiedVisitor.name;
                    prevState[visitorIndex!].organization = modifiedVisitor.organization;
                    prevState[visitorIndex!].host_name = modifiedVisitor.host_name;
                    return [...prevState];
                });
            }
        } else
        {
            const newVisitor = await addNewVisitor(visitor, searchParams.get("key") ?? "", notificationDispatch, {accounts: accounts, instance: instance});
            if(newVisitor)
            {
                setVisitors(current => [...current, newVisitor]);
                setIsRegistrationSuccessful!(true);
            }
        }
    }

    const handleBranchSelection = (branch: string) =>
    {
        setSelectedBranch({_id: branch.split("-")[0], address: branch.split("-")[1]});
    }

    return (
        <div className={`${isEdit ? styles.edit_form_container : ""}`} onClick={() => isEdit && setEditClicked ? setEditClicked(current => !current) : null} onTouchEnd={() => isEdit && setEditClicked ? setEditClicked(current => !current) : null}>
            <div className={`${isEdit ? styles.edit_form : ""}`} onClick={e => isEdit ? e.stopPropagation() : null} onTouchEnd={e => isEdit ? e.stopPropagation() : null}>
                <form className={`${styles.form} + ${isEdit ? styles.form_edit : ""}`}
                      onSubmit={e => formValidation(e, [{inputName: isEdit || (isHost(groupsID) || isAdmin(groupsID)) ? t("visitorForm.editName") : t("visitorForm.name"), input: name, skip: false}, {inputName: t("visitorForm.organizationName"), input: organization, skip: false}, {inputName: t("visitorForm.hostName"), input: hostName, skip: false}, {inputName: t("verificationForm.cardNumber"), input: cardNumber, skip: isEdit || !isHost(groupsID) || !isAdmin(groupsID)}, {inputName: t("verificationForm.verifyMethod"), input: checkedDocument, skip: isEdit || !isHost(groupsID) || !isAdmin(groupsID)}], notificationDispatch, handleSubmit)}>

                    <div className={styles.form_content_container}>
                        <div className={styles.form_default_options_container}>

                            <label>
                                {isEdit || (isHost(groupsID) || isAdmin(groupsID)) ? t("visitorForm.editName") : t("visitorForm.name")}
                                <input type="text" value={name} required={true} onChange={e => setName(e.target.value)} />
                            </label>
                            <label>
                                {t("visitorForm.organizationName")}
                                <input type="text" value={organization} required={true} onChange={e => setOrganization(e.target.value)} />
                            </label>
                            <label>
                                {t("visitorForm.hostName")}
                                <input type="text" value={hostName} required={true} onChange={e => setHostName(e.target.value)} />
                            </label>
                        </div>

                        {!isEdit && (isHost(groupsID) || isAdmin(groupsID)) &&
                            <div className={styles.host_admin_form_options}>
                                <FormDatePicker setVisitingDate={setVisitingDate} visitingDate={visitingDate} />

                                {(branchId === undefined || branchAddress === undefined) &&
                                    <label>
                                        {t("backEndMessages.branch")}*
                                        <select required={true}
                                                defaultValue={`selectBranch`}
                                                onChange={e => handleBranchSelection(e.target.value)}>
                                            <option disabled={true} value={`selectBranch`}>{t("visitorForm.selectABranch")}</option>
                                            {allBranches.map(branch =>
                                                <option key={branch._id}
                                                        value={`${branch._id}-${branch.address}`}>{branch.address}</option>)}
                                        </select>
                                    </label>
                                }

                                <label>
                                    {t("verificationForm.cardNumber")}
                                    <input type="text" required={true} onChange={e => setCardNumber(e.target.value)} />
                                </label>
                                <span>{t("verificationForm.verifyMethod")}</span>
                                <label className={styles.document_check}>
                                    <input type="checkbox"
                                           checked={checkedDocument === "passport"}
                                           onChange={() => setCheckedDocument("passport")}/>
                                    {t("verificationForm.passport")}
                                </label>

                                <label className={styles.document_check}>
                                    <input type="checkbox"
                                           checked={checkedDocument === "driverLicense"}
                                           onChange={() => setCheckedDocument("driverLicense")}/>
                                    {t("verificationForm.driverLicense")}
                                </label>

                                <label className={styles.document_check}>
                                    <input type="checkbox"
                                           checked={checkedDocument === "id"}
                                           onChange={() => setCheckedDocument("id")}/>
                                    {t("verificationForm.id")}
                                </label>

                                <label className={styles.document_check}>
                                    <input type="checkbox"
                                           checked={checkedDocument === "knownPerson"}
                                           onChange={() => setCheckedDocument("knownPerson")}/>
                                    {t("verificationForm.knownPerson")}
                                </label>

                                <label className={`${styles.document_check} ${styles.document_check_other}`}>
                                    <span>{t("verificationForm.other")}</span>
                                    <input type="text" onChange={e => setCheckedDocument(e.target.value)} />
                                </label>
                            </div>
                        }
                    </div>

                    {!isEdit &&
                        <label className={styles.terms}>
                            <input type="checkbox" required={true} />
                            <span>
                                {t(`visitorForm.${(isHost(groupsID) || isAdmin(groupsID)) ? "termsOfConditionHostAdmin" : "termsOfCondition"}`)}
                            </span>
                        </label>
                    }

                    <input type="submit" value={isEdit ? t("visitorForm.saveBtn") as string : t(`visitorForm.${(isHost(groupsID) || isAdmin(groupsID)) ? "registerVisitor" : "registerBtn"}`) as string} />

                </form>
            </div>
        </div>
    );
}

export default VisitorForm;
